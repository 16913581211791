import request from '@/utils/request'; // 登录接口

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
} // 发送验证码

export function getVerificationCode(data) {
  return request({
    url: '/send-msg',
    method: 'post',
    data: data
  });
} // 退出登录

export function logout(data) {
  return request({
    url: '/logout',
    method: 'get',
    params: data
  });
}